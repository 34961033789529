import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCards1 from "./ProjectCards1";
import ProjectCards2 from "./ProjectCards2";
import ProjectCards3 from "./ProjectCards3";
import ProjectCards4 from "./ProjectCards4";
import ProjectCards5 from "./ProjectCards5";
import ProjectCards6 from "./ProjectCards6";
import ProjectCards7 from "./ProjectCards7";
import ProjectCards8 from "./ProjectCards8";
import ProjectCards9 from "./ProjectCards9";
import Particle from "../Particle";

import img1 from "../../Assets/Projects/img1.png";
import img2 from "../../Assets/Projects/img2.png";
import img3 from "../../Assets/Projects/img3.png";
import img4 from "../../Assets/Projects/img4.png";
import img5 from "../../Assets/Projects/img5.png";
import img6 from "../../Assets/Projects/img6.png";
import img7 from "../../Assets/Projects/img7.png";
import img8 from "../../Assets/Projects/img8.png";
import img9 from "../../Assets/Projects/img9.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My <strong className="green"> Work </strong>
        </h1>
        <p style={{ color: "white" }}>
          Some projects, research, and inventions I have been working on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCards1
              imgPath={img1}
              isBlog={false}
              title="Artificial Intelligence"
              description="With a robust background in AI, I completed a yearlong fellowship at Stanford Law School, focusing on reinforcement learning patents. During this tenure, I conducted in-depth research on the legal and ethical implications of AI while simultaneously developing AI software for patent valuation. My expertise spans both theoretical analysis, reflected in published surveys on AI patents, and practical implementation, demonstrated through hands-on coding and software development in various AI applications."
              ghLink="https://law.stanford.edu/publications/no-63-reinforcement-learning-patents-a-transatlantic-review/"
              ghLink1="https://scholarship.kentlaw.iit.edu/ckjip/vol19/iss3/6/"
              ghLink2="https://lira.bc.edu/work/sc/108271f6-12bf-44b9-887f-a6ef39e3f33d"
 
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards2
              imgPath={img2}
              isBlog={false}
              title="Tego"
              description="Tego's mission is to spearhead research and development efforts aimed at discovering novel cures and treatments for thyroid disease. Through decentralized collaboration and innovation, Tego is committed to leveraging cutting-edge technologies and interdisciplinary expertise to address the complexities of thyroid-related health issues. By fostering an inclusive and transparent ecosystem, Tego aims to accelerate the pace of discovery, ultimately improving the quality of life for individuals affected by thyroid disease worldwide."
              ghLink="https://tegotech.org"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards3
              imgPath={img3}
              isBlog={false}
              title="Rockets"
              description="Engaged in pioneering advancements in aerospace technologies, my focus lies in inventing innovative solutions for autonomous rocket control. Addressing the complexities of unpredictable environments, my work aims to revolutionize rocketry by developing cutting-edge technologies that enable precise and autonomous navigation, regardless of weather conditions. Through a combination of advanced sensors, sophisticated algorithms, and robust control systems, my research endeavors to redefine the possibilities of space exploration, opening up new frontiers for humanity's journey beyond Earth."
              ghLink="https://patents.google.com/patent/US11613384B2/en"
              ghLink1="https://patents.google.com/patent/US20230249847A1/en"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards4
              imgPath={img4}
              isBlog={false}
              title="Blockchain"
              description="With a wealth of experience in blockchain technology, including managing global OSS projects and winning grants for blockchain governance software development, I was honored as the top prize winner at the 2021 MIT Bitcoin Hackathon. Leading global OSS projects in areas such as blockchain voting, smart contracts, and security, I have overseen the coordination of diverse teams to drive innovation and advance the capabilities of blockchain systems."
              ghLink="https://www.choice-coin.com/projects"           
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCards5
              imgPath={img5}
              isBlog={false}
              title="High Technology Patents"
              description="The purpose for High Technology Patents is to give inventors freedom and a book they love. Describing innovation as a productive process, this Book is a guide for bringing ideas to life. Everyone has great ideas that can make the world a better place. But most of those ideas never become inventions because significant economic, legal, and regulatory barriers blockade the path to progress. This Book breaks the blockade, clearing the convolution and changing innovation."
              ghLink="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3917614"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards6
              imgPath={img6}
              isBlog={false}
              title="Quantum Computing"
              description="
              My expertise in quantum computing encompasses both theoretical analysis and practical implementation. I have authored comprehensive surveys on quantum computing patents, shedding light on the evolving landscape of this cutting-edge technology. Furthermore, my research extends to the intersection of quantum computing and machine learning, where I have conducted surveys specifically on quantum machine learning patents, exploring the synergies between these two fields."
              ghLink="https://www.bu.edu/jostl/files/2021/06/2-Haney.pdf"
              ghLink1="https://scholarlycommons.law.case.edu/jolti/vol12/iss1/6/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards7
              imgPath={img7}
              isBlog={false}
              title="Millenium Problems"
              description="Having made significant progress by publishing solutions to two of the Millennium Prize Problems, I am now actively seeking like-minded researchers to collaborate on tackling the remaining challenges. By pooling our collective expertise and perspectives, we can work towards further advancements in mathematical and theoretical physics research, aiming to contribute meaningfully to the field and address some of its most profound questions."
              ghLink="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3673750"
              ghLink1="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3801362"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards8
              imgPath={img8}
              isBlog={false}
              title="Compliance Automation"
              description="My expertise in compliance automation spans diverse sectors, including government contracts and digital assets. Notably, my research on government contract compliance was recognized by the American Bar Association and presented at Code X, Stanford Law School's renowned conference. Through my work, I strive to drive efficiency, transparency, and accountability in regulatory compliance processes, facilitating smoother operations and mitigating risks for organizations navigating complex regulatory landscapes."
              ghLink="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4189147"
              ghLink1="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3261360"
              ghLink2="https://tlp.law.pitt.edu/ojs/tlp/article/view/225"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards9
              imgPath={img9}
              isBlog={false}
              title="Open Source Software"
              description="With a rich background as an open-source software (OSS) developer, I have honed my expertise across multiple domains, with a primary focus on blockchain and AI. Leveraging my proficiency in programming languages such as Python and JavaScript, I have actively contributed to the development of various open-source projects, driving innovation and collaboration within the community. Through my experience as an OSS developer, I remain dedicated to pushing the boundaries of what is possible, fostering transparency, accessibility, and innovation in software development."
              ghLink="https://github.com/Bhaney44"
            />
          </Col>



        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
