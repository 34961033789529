import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/homeLogo.png";
import Tilt from "react-parallax-tilt";
import Button from "react-bootstrap/Button";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Connect <span className="purple"> With Me </span> 
            </h1>
            <p>Email: brian.s.haney44@gmail.com</p>
            <p>
              <Button variant="primary" href="https://scholar.google.com/citations?user=6_XhYkQAAAAJ&hl=en&oi=ao" target="_blank">
                {"Scholar"}
              </Button>
            </p>
            <p>
              <Button variant="primary" href="https://github.com/Bhaney44" target="_blank">
                {"GitHub"}
              </Button>
            </p>

          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>

        </Row>
        
      </Container>
    </Container>
  );
}
export default Home2;
